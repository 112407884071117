<template>
  <v-sheet style="border-left: 2px solid red;padding-left: 15px;overflow-y: hidden;" :max-height="maxHeight">
    <div :style="'max-height:' + (maxHeight - 10) + 'px'" class="d-block">
      <div v-if="internalValue !== ''">
        {{ internalValue }}
      </div>
      <slot v-else></slot>
    </div>
    <a @click="viewDetail = true" href="javascript:;">Read More ... </a>


    <v-dialog v-model="viewDetail" max-width="800px">
      <v-card outlined>
        <v-card-title>Info
          <v-spacer></v-spacer>
          <v-btn icon text @click="viewDetail = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="max-height: 400px;overflow-y: auto;">
          <div class="d-block">
            <div v-if="internalValue !== ''">
              {{ internalValue }}
            </div>
            <slot v-else></slot>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="viewDetail = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: Number,
      default: 60
    },
  },
  data() {
    return {
      internalValue: this.value,
      viewDetail: false,
    }
  },
  watch: {
    value(val) {
      this.internalValue = val
    },
    internalValue(val) {
      this.$emit('input', val)
    }
  }
}
</script>