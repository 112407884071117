const config = {
  // Live
  apiUrl: 'https://api.betonperkasa.com/bpw/',
  base_url: 'https://bpw.betonperkasa.com/',
  base_url_domain: 'bpw.betonperkasa.com',

  // Development
  // apiUrl: process.env.VUE_APP_API_URL,
  // base_url: 'http://192.168.2.18:8081/',
  // base_url_domain: '192.168.2.18:8081',

  notificationPlugin: "awn", // awn|notify

  notificationRefreshTime: 5000,

  axiosErrorResponseMessage: "Oops.. Something went wrong, please contact IT Administrator.",

  // Navbar Menu
  navbarMenu: [
    // Dashboard
    {
      title: "Dashboard",
      routeMap: "Dashboard",
      icon: "mdi-view-dashboard",
      to: { name: "Dashboard" },
      module: "",
      active: false,
      perm_name: "",
      items: [],
    },
    // Sales
    {
      title: "Sales & Marketing",
      routeMap: "Sales",
      icon: "mdi-shopping",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        {

          title: "Customers",
          routeMap: "Customers",
          icon: "",
          to: {},
          module: "",
          active: false,
          perm_name: "",
          items: [
            {
              title: "Contact",
              routeMap: "Sales.Contact",
              icon: "",
              to: { name: "Sales.Contact" },
              module: "contact",
              active: false,
              perm_name: "view",
              items: [],
            },
            {
              title: "Customer",
              routeMap: "Sales.Customer",
              icon: "",
              to: { name: "Sales.Customer" },
              module: "customer",
              active: false,
              perm_name: "view",
              items: [],
            },
            {
              title: "Campaign",
              routeMap: "Sales.Campaign",
              icon: "",
              to: { name: "Sales.Campaign" },
              module: "campaign",
              active: false,
              perm_name: "view",
              items: [],
            },
            {
              title: "Ticket",
              routeMap: "Sales.Ticket",
              icon: "",
              to: { name: "Sales.Ticket" },
              module: "ticket",
              active: false,
              perm_name: "view",
              items: [],
            },
          ]
        },
        {
          title: "Pricing",
          routeMap: "Pricing",
          icon: "",
          to: {},
          module: "",
          active: false,
          perm_name: "",
          items: [
            {
              title: "Sales Price",
              routeMap: "Sales.SalesPrice",
              icon: "",
              to: { name: "Sales.SalesPrice" },
              module: "salesprice",
              active: false,
              perm_name: "view",
              items: [],
            }
          ],
        },
        {
          title: "Contract",
          routeMap: "Contract",
          icon: "",
          to: {},
          module: "",
          active: false,
          perm_name: "",
          items: [
            {
              title: "Resume Kontrak",
              routeMap: "Accounting.ContractResume",
              icon: "mdi",
              to: { name: "Accounting.ContractResume" },
              module: "resumekontrak",
              active: false,
              perm_name: "view",
              items: [],
            },
            {
              title: "Addendum",
              routeMap: "Accounting.Addendum",
              icon: "mdi",
              to: { name: "Accounting.Addendum" },
              module: "addendum",
              active: false,
              perm_name: "view",
              items: [],
            },
            {
              title: "Checklist Dokumen BA ABD",
              routeMap: "Accounting.ChecklistDokumenBAABD",
              icon: "mdi",
              to: { name: "Accounting.ChecklistDokumenBAABD" },
              module: "checklistdokumenbaabd",
              active: false,
              perm_name: "view",
              items: [],
            },
            {
              title: "BA ABD",
              routeMap: "Accounting.BAABD",
              icon: "mdi",
              to: { name: "Accounting.BAABD" },
              module: "baabd",
              active: false,
              perm_name: "view",
              items: [],
            },
          ],
        },
        // {
        //   title: "Upload",
        //   routeMap: "Sales.DocumentUploads",
        //   icon: "mdi-upload",
        //   to: { name: "Sales.DocumentUploads" },
        //   module: "document_upload",
        //   active: false,
        //   perm_name: "view",
        //   items: [],
        // },
      ],
    },  
    // Warehouse
    {
      title: "Warehouse",
      icon: "mdi-truck",
      routeMap: "Warehouse",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        // {
        //   title: "Shipment Material",
        //   icon: "mdi-list",
        //   to: { name: "ShipmentMaterial" },
        //   module: "",
        //   active: false,
        //   perm_name: "",
        //   items: [],
        // },
        {
          title: "RM Criteria",
          icon: "mdi-list",
          to: { name: "Warehouse.RMCriteria" },
          module: "rmcriteria",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Return Material",
          icon: "mdi-list",
          to: { name: "Warehouse.ReturnMaterial" },
          module: "rmp",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Item",
          icon: "mdi-box",
          to: { name: "Warehouse.Item" },
          module: "item",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Bin Content",
          icon: "mdi-box",
          to: { name: "Warehouse.BinContent" },
          module: "bincontent",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Booking Material",
          icon: "mdi-box",
          to: { name: "Warehouse.BookingMaterial" },
          module: "bookingmaterial",
          active: false,
          perm_name: "view",
          items: [],
        },
      ],
    },
    // Accounting
    {
      title: "Accounting",
      routeMap: "Accounting",
      icon: "mdi-file-document",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        {
          title: "Berita Acara",
          routeMap: "Accounting.BeritaAcara",
          icon: "mdi",
          to: { name: "Accounting.BeritaAcara" },
          module: "beritaacara",
          active: false,
          perm_name: "view",
          items: [],
        },
      ],
    },
    // Purchasing
    {
      title: "Purchasing",
      routeMap: "Purchasing",
      icon: "mdi-cart-outline",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        {
          title: "Vendor",
          routeMap: "Purchasing.BeritaAcara",
          icon: "mdi",
          to: { name: "Purchasing.BeritaAcara" },
          module: "",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Vendor",
          routeMap: "Purchasing.BeritaAcara",
          icon: "mdi",
          to: { name: "Purchasing.BeritaAcara" },
          module: "",
          active: false,
          perm_name: "view",
          items: [],
        },
      ],
    },
    // Human Resource
    {
      title: "Human Resource",
      routeMap: "HumanResource",
      icon: "mdi-account-star",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        {
          title: "Department",
          routeMap: "HumanResource.Department",
          icon: "mdi",
          to: { name: "HumanResource.Department" },
          module: "department",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Division",
          routeMap: "HumanResource.Division",
          icon: "mdi",
          to: { name: "HumanResource.Division" },
          module: "division",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Employee",
          routeMap: "HumanResource.Employee",
          icon: "mdi",
          to: { name: "HumanResource.Employee" },
          module: "employee",
          active: false,
          perm_name: "view",
          items: [],
        },
      ],
    },
    // Administrasion
    {
      title: "Administration",
      routeMap: "Administration",
      icon: "mdi-file-document",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        {
          title: "CAPA",
          routeMap: "Administration.CPAR",
          icon: "mdi",
          to: { name: "Administration.CPAR" },
          module: "cpar",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "MJO",
          routeMap: "Administration.MJO",
          icon: "mdi",
          to: { name: "Administration.MJO" },
          module: "mjo",
          active: false,
          perm_name: "view",
          items: [],
        },
      ],
    },
    // Setting
    {
      title: "Setting",
      routeMap: "Setting",
      icon: "mdi-cog",
      to: {},
      module: "",
      active: false,
      perm_name: "",
      items: [
        {
          title: "General",
          routeMap: "Setting.General",
          icon: "mdi",
          to: { name: "Setting.General" },
          module: "setting",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "User",
          icon: "mdi-users",
          to: { name: "Setting.User" },
          module: "user",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Campaign",
          routeMap: "Setting.RegisteredCampaign",
          icon: "",
          to: { name: "Setting.RegisteredCampaign" },
          module: "registeredcampaign",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Approval Template",
          routeMap: "Setting.ApprovalTemplate",
          icon: "",
          to: { name: "Setting.ApprovalTemplate" },
          module: "approvaltemplate",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "Approval Modifier",
          routeMap: "Setting.ApprovalModifier",
          icon: "",
          to: { name: "Setting.ApprovalModifier" },
          module: "approvalmodifier",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "CPAR Source",
          routeMap: "Setting.CPARSource",
          icon: "mdi",
          to: { name: "Setting.CPARSource" },
          module: "cparsource",
          active: false,
          perm_name: "view",
          items: [],
        },
        {
          title: "CPAR Category",
          routeMap: "Setting.CPARCategory",
          icon: "mdi",
          to: { name: "Setting.CPARCategory" },
          module: "cparcategory",
          active: false,
          perm_name: "view",
          items: [],
        },
      ],
    },
    // About
    {
      title: "About",
      routeMap: "About",
      icon: "mdi-help-box",
      to: { name: "About" },
      items: [],
      module: "",
      active: false,
      perm_name: "",
    },
  ],
};

export default config;
