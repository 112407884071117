<template>
  <v-list dense>
    <template v-for="(menu1, i) in menuItems">
      <v-list-item v-if="menu1.items.length == 0" link :to="menu1.to" :key="i">
        <v-list-item-icon>
          <v-icon>{{ menu1.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ menu1.title }}</v-list-item-title>
      </v-list-item>

      <v-list-group v-if="menu1.items.length >= 1" :key="menu1.title" :prepend-icon="menu1.icon" no-action :value="isActiveMenu(menu1)">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ menu1.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <!-- <template v-if="menu1.items.length >= 1">
          <v-list-item v-for="menu2 in menu1.items" :key="menu2.title" link :to="menu2.to">
            <v-list-item-content>
              <v-list-item-title v-text="menu2.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->
        <template v-for="(menu2, i) in menu1.items">
          <v-list-item v-if="menu2.items.length == 0" link :to="menu2.to" :key="i">
            <v-list-item-title>{{ menu2.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-if="menu2.items.length >= 1" :key="menu2.title" no-action sub-group :value="isActiveMenu(menu2)">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ menu2.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-if="menu2.items.length >= 1">
              <v-list-item v-for="menu3 in menu2.items" :key="menu3.title" link :to="menu3.to">
                <v-list-item-content>
                  <v-list-item-title>{{ menu3.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>


      </v-list-group>
    </template>
  </v-list>
</template>
<script>
import config from '../../constants/config'
export default {
  components: {},
  data() {
    return {
      drawer: null,
      items: [],
      menuItems: [],
      right: null,
      loading: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    '$store.state.userModule': function (n, o) {
      this.renderMenu()
    }
  },
  computed: {
    // menuItems(){
    //   return this.showMenu(config.navbarMenu);
    // }
  },
  methods: {
    checkAccessChild(items) {
      var _items = [];
      items.forEach((item) => {
        if (typeof item.items !== "undefined" && item.items.length > 0) {
          item.items = this.showMenu(item.items);
        }
        console.log(item);
        if (item.module == "" && item.perm_name == "") {
          if (typeof item.items !== "undefined" && item.items.length > 0) {
            _items.push(item);
          }
        } else {
          if (this.modulePermission(item.module, item.perm_name)) {
            _items.push(item);
          }
        }
      });
    },
    showMenu(items) {
      var _items = [];
      items.forEach((item) => {
        // Check if parent
        if (JSON.stringify(item.to) === JSON.stringify({})) {
          item.items = this.showMenu(item.items);
          if (item.items.length > 0) {
            _items.push(item);
          }
        } else {
          if (this.modulePermission(item.module, item.perm_name) || ((item.module == '' && item.perm_name == ''))) {
            _items.push(item);
          }
        }
      });
      return _items;
    },
    setMenuActive() {
      for (var i = 0; i < this.menuItems.length; i++) {
        if ((typeof this.menuItems[i].routeMap !== 'undefined') && (typeof this.$route.meta.routeMap !== 'undefined')) {
          if (this.$route.meta.routeMap.includes(this.menuItems[i].routeMap)) {
            this.menuItems[i].active = true
          } else {
            this.menuItems[i].active = false
          }
        }
      }
    },
    isActiveMenu(menu) {
      if (typeof menu.items == 'undefined' || menu.items.length == 0) {
        if ((typeof menu.routeMap !== 'undefined') && (typeof this.$route.meta.routeMap !== 'undefined')) {
          if (this.$route.meta.routeMap.includes(menu.routeMap)) {
            return true;
          } else {
            return false;
          }
        }
      } else if (menu.items.length >= 1) {
        let actv = false;
        for (let i = 0; i < menu.items.length; i++) {
          let menu2 = menu.items[i];
          if(this.isActiveMenu(menu2) === true){
            actv = true;
          }
        }

        return actv;
      }
    },
    renderMenu() {
      this.menuItems = this.showMenu(this.items);
    },
    async getModule() {
      if (!this.isLoggedIn()) return;

      var params = [];

      this.AxiosStorageRemove('GET', "auth/user-module");

      await this.$axios
        .get("auth/user-module", {
          params: params,
        })
        .then((res) => {
          var resData = res.data;
          this.$store.state.userModule = JSON.stringify(resData.data.user_modules)

          this.renderMenu();
        })
        .catch((error) => {
          if (error.response) {
            console.log("Error: ", error.response.headers);
          } else {
            console.log("Error: ", error.message);
          }
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.renderMenu()
      this.setMenuActive()
    }, 500)
  },
  created() {
    this.items = config.navbarMenu

    // this.getModule();

    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (toParams, previousParams) => {
        // react to route changes...
        this.setMenuActive()
      }
    )

  }
};
</script>