<template>
  <v-dialog max-width="1200" v-model="dialog">
    <v-card>
      <v-card-title class="d-flex" style="line-height: normal;">
        <v-avatar>
          <v-img v-if="(typeof data.user_img !== 'undefined')" :src="resourceUrl(data.user_img)"></v-img>
        </v-avatar>
        <div class="px-2">
          <h6 style="font-size: 14px;" v-if="(typeof data.created_by_username !== 'undefined')">{{ data.created_by_username }}</h6>
          <small class="d-block text--disabled" style="font-size: x-small;" v-if="(typeof data.employee_position !== 'undefined')">{{ data.employee_position }}</small>
          <small class="d-block text--disabled" style="font-size: x-small;" v-if="(typeof data.created_date !== 'undefined')">{{ appDatetimeFormat(data.created_date) }}</small>
        </div>
      </v-card-title>

      <v-card-text>
        <div>
          <div class="mb-2" v-if="data.reply_id > 0" style="max-height: 300px; overflow-y: auto">
            <v-sheet class="px-3 rounded mt-2" color="grey lighten-4" outlined style="border-left: 2px solid red !important;">
              <h6 v-if="data.replied_created_by_username" style="font-size: 12px;"><app-user-info :username="data.replied_created_by_username">@{{ data.replied_created_by_username }}</app-user-info>
                <small class="text--disabled" style="font-size: 12px;font-weight: normal;margin-left: 4px">{{ timeAgo(data.replied_created_date) }}</small>
              </h6>
              <pre v-if="data.replied_created_by_username" class="ipre_reply" style="white-space: pre-wrap;">{{ data.replied_comment_body }}</pre>

              <span v-if="!data.replied_created_by_username" style="font-size: 12px; font-style: italic;">Comment deleted...</span>
            </v-sheet>
          </div>
          <div style="max-height: 300px; overflow-y: auto">
            <pre class="ipre" style="white-space: pre-wrap;" v-if="!show_edited">{{ data.comment_body }}</pre>
            <pre class="ipre" style="white-space: pre-wrap;" v-else>{{ data.old_comment_body }} <span class="text--disabled"  style="font-size: small;font-style: italic;"> - old</span></pre>
          </div>
        </div>

        <!-- <v-textarea style="border: none !important;width: 100%; max-height: 300px" rows="" :value="data.comment" readonly class="mb-1" hide-details></v-textarea> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ["data", "value"],
  data() {
    return {
      dialog: this.value
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>
div.cpre {
  max-height: 260px;
  overflow-y: auto;
}

.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>