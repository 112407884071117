<!-- eslint-disable vue/no-unused-components -->
<template>
  <div>
    <v-card elevation="0" outlined v-bind="$attrs" v-on="$listeners" v-model="internalValue">
      <v-toolbar elevation="0" dense>
        <v-toolbar-title><v-icon>mdi-comment-multiple</v-icon> Comments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small icon @click="dialog_comment = true">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-window-maximize</v-icon>
            </template>
            <span>Pop Out</span>
          </v-tooltip>
        </v-btn>
        <v-btn text small icon @click="getData(true)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-refresh</v-icon>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-btn>
        <v-btn text small icon @click="dialog_add = true">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-comment-plus-outline</v-icon>
            </template>
            <span>Add New Comment</span>
          </v-tooltip>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <app-comment-list-items :document_id="parseInt(document_id)" :source_document="source_document" :document_no_="document_no_" ref="refAppCommentListItems"></app-comment-list-items>
    </v-card>


    <app-comment-add :document_id="parseInt(document_id)" :source_document="source_document" :document_no_="document_no_" @callback="getData(true)" v-model="dialog_add"></app-comment-add>
    <app-comment-dialog :document_id="parseInt(document_id)" :source_document="source_document" :document_no_="document_no_" @callback="getData(true)" v-model="dialog_comment" :title="dialog_comment_title"></app-comment-dialog>

  </div>
</template>

<script>
// import CommentCard from './CommentCard';
export default {
  inheritAttrs: false,
  props: {
    value: { // Menggunakan prop 'value' karena Vue 2 menggunakan v-model dengan prop 'value'
      type: Boolean,
      default: false,
    },
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    document_no_: {
      type: String,
      default: "",
    },
    elevation: {
      type: Number,
      default: 1,
    }
  },
  components: {
    // CommentAdd,
    // eslint-disable-next-line vue/no-unused-components
    // CommentCard,
  },
  data() {
    return {
      internalValue: this.value,
      minHeightContent: '',
      internalTitle: '',

      dialog: false,
      loading: false,

      dialog_add: false,
      dialog_comment: false,

    }
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        if (typeof this.callback == 'function')
          this.callback();
      }
    },
  },
  computed: {
    dialog_comment_title() {
      if (this.document_no_ != '') {
        return 'Comment for ' + this.document_no_
      }
      return 'Comment';
    }
  },
  methods: {
    getData(refresh = false) {
      this.$refs.refAppCommentListItems.getData(refresh);
    }
  },
}
</script>