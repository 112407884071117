<!-- eslint-disable vue/no-unused-components -->
<template>
  <div>
    <v-card :elevation="0">
      <v-card-text>
        <div class="d-flex">
          <v-textarea v-model="comment_body" :disabled="loading_add" outlined class="fill-height" rows="2" hide-details label="Write Comment Here.."></v-textarea>
          <v-btn text class="px-0 mx-0" small @click="saveComment()">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text>
        <div class="d-flex">
          <span class="text--caption">List Comments</span>
          <v-spacer></v-spacer>
          <v-btn @click="datatable.sortDesc = !datatable.sortDesc; getData(true)" small text class="float-right">
            <v-icon v-if="!datatable.sortDesc">mdi-sort-descending</v-icon>
            <v-icon v-else>mdi-sort-ascending</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table :loader-height="0" :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" :hide-default-header="true" dense class="py-3 my-0 commenttable" hide-default-footer item-class="nohover" @page-count="pageCount = $event" :sort-by="datatable.sortBy" :sort-desc="datatable.sortDesc">
        <template v-slot:[`item.id`]="{ item }">
          <app-comment-card :data="item" @after-delete="getData(true)" @after-edit="getData(true)" @after-reply="getData(true)"></app-comment-card>
        </template>

        <template #loading>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Comment not found!</v-alert>

      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination circle v-model="datatable_options.page" :total-visible="7" :length="totalPage"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
// import CommentCard from './CommentCard';
export default {
  props: {
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    document_no_: {
      type: String,
      default: "",
    },
    elevation: {
      type: Number,
      default: 1,
    }
  },
  components: {
    // CommentAdd,
    // eslint-disable-next-line vue/no-unused-components
    // CommentCard,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      comments: [],
      limit: 5,
      dialog_add: false,
      search: "",
      datatable_options: {
        page: 1,
        itemsPerPage: 5,
      },
      itemsPerPageOption: [5, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "id", value: "id", sortable: false },
        ],
        data: [],
        sortBy: 'id',
        sortDesc: false,
      },

      loading_add: false,
      comment_body: "",
    }
  },
  computed: {
    comments_limit() {
      var cl = [];
      for (var i = 0; i < this.limit; i++) {
        cl.push(this.comments[i]);
      }
      return cl;
    },

    _document_id() {
      return this.document_id;
    },

    _source_document() {
      return this.source_document;
    },

    _document_no_() {
      return this.document_no_;
    }
  },
  watch: {
    document_id(val) {
      if (val) {
        this.getData(true)
      }
    },
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    async getData(refresh) {
      const uri = "comments/data";
      if (refresh) this.AxiosStorageRemove("POST", uri);

      this.datatable.loading = true;

      var formData = this.dataTableFormData(this.datatable_options, this.search, {
        document_id: this._document_id,
        source_document: this._source_document,
        // document_no_: this._document_no_,
      });

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    async saveComment() {
      if (this.comment_body.toString().trim().length === 0) return this.showAlert('error', 'Can\'t empty!');
      this.loading_add = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("document_id", this.document_id);
      formData.append("source_document", this.source_document);
      // formData.append("document_no_", this.document_no_);
      formData.append("comment_body", this.comment_body);
      await this.$axios.post("comments/save", formData)
        .then((res) => {
          this.loading_add = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.comment_body = ""

          this.getData(true);
        })
        .catch((error) => {
          this.loading_add = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.getData(true);
        });
    },
  },

  mounted() {
  }
}
</script>

<style scoped>
::v-deep .v-data-table__wrapper tbody tr:hover {
  background: #fff !important;
}

::v-deep .v-data-table__wrapper tbody td {
  border: none !important;
}
</style>