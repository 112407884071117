<!-- eslint-disable vue/no-unused-components -->
<template>
  <div>
    <v-card elevation="0">
      <app-comment-list-items :document_id="parseInt(document_id)" :source_document="source_document"></app-comment-list-items>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" outlined elevation="0" block small><v-icon>mdi-window-maximize</v-icon> Pop Out</v-btn>
          </template>
          <span>Pop Out</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <app-comment-dialog :document_id="parseInt(document_id)" :source_document="source_document" v-model="comment_dialog"></app-comment-dialog>
  </div>
</template>

<script>
// import CommentCard from './CommentCard';
export default {
  props: {
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    elevation: {
      type: Number,
      default: 1,
    }
  },
  components: {
    // CommentAdd,
    // eslint-disable-next-line vue/no-unused-components
    // CommentCard,
  },
  data() {
    return {
      dialog: false,
      loading: false,

      comment_dialog: false
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    popOut() {
      this.comment_dialog = true;
    },
  },
}
</script>