import Vue from 'vue'
import '@/styles/generic/_index.scss'
import '@/styles/variable.scss'
import '@/plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import Notifications from 'vue-notification'
import VueAWN from "vue-awesome-notifications"
import helperMixin from './mixins/helperMixin'
import loginMixin from './mixins/loginMixin'
import appLocalStorageMixin from './mixins/appLocalStorageMixin'
import VuejsDialog from 'vuejs-dialog';
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'animate.css';
import CardExpansion from "@/components/CardExpansion.vue";
import AppDialog from "@/components/AppDialog.vue";
import AppPageEdit from "./components/AppPageEdit.vue";
import AppRightBar from "./components/AppRightBar.vue";
import AppPageDetail from "@/components/AppPageDetail.vue";
import AppDateTime from "@/components/AppDateTime.vue";
import AppComment from "@/components/AppComment.vue";
import AppTextInfo from "@/components/AppTextInfo.vue";
import PageTitle from "@/components/PageTitle.vue";
import PageToolbar from "@/components/PageToolbar.vue";
import ImageViewer from "@/components/helper/ImageViewer.vue";
import ShowImageDialog from "@/components/ShowImageDialog.vue";
import FlyingButton from "@/components/FlyingButton.vue";
import Rightbar from '@/components/Rightbar';
import ActionBoxBottom from '@/components/ActionBoxBottom';
import AppTooltip from '@/components/AppTooltip';
import CommentAdd from '@/components/helper/CommentAdd.vue';
import CommentEdit from '@/components/helper/CommentEdit.vue';
import CommentReply from '@/components/helper/CommentReply.vue';
import CommentCard from '@/components/helper/CommentCard.vue';
import CommentList from '@/components/helper/CommentList.vue';
import CommentDialog from '@/components/helper/CommentDialog.vue';
import CommentDetail from '@/components/helper/CommentDetail.vue';
import CommentListItems from '@/components/helper/CommentListItems.vue';
import LogDataDialog from '@/components/helper/LogDataDialog.vue';
import Datepicker from '@/components/Datepicker.vue';
import VueHtml2pdf from 'vue-html2pdf'
import '@/assets/styles.css'
import config from "@/constants/config";
import options from "@/constants/options";
import AppButtonClose from '@/components/button/AppButtonClose.vue';
import AppButton from '@/components/AppButton.vue';
import AppDocumentStatus from '@/components/AppDocumentStatus.vue';
import AppUserInfo from '@/components/AppUserInfo.vue';


// Global Scope
Vue.prototype.$config = config;
Vue.prototype.$app_options = options;
Vue.prototype.$helper = helperMixin;

// Config
Vue.config.productionTip = false;

// Plugins
Vue.use(VueAWN, {
  animationDuration:50,
  position : "bottom-right"
});
Vue.use(Notifications);
Vue.use(VuejsDialog);

// Mixins
Vue.mixin(helperMixin);
Vue.mixin(loginMixin);
Vue.mixin(appLocalStorageMixin);
Vue.use(VueHtml2pdf)

// Components
Vue.component('card-expansion', CardExpansion);
Vue.component('page-title', PageTitle);
Vue.component('page-toolbar', PageToolbar);
Vue.component('image-viewer', ImageViewer);
Vue.component('show-image-dialog', ShowImageDialog);
Vue.component('flying-button', FlyingButton);
Vue.component('rightbar', Rightbar);
Vue.component('action-box-bottom', ActionBoxBottom);

Vue.component('app-tooltip', AppTooltip);
Vue.component('app-comment', AppComment);
Vue.component('app-comment-card', CommentCard);
Vue.component('app-comment-add', CommentAdd);
Vue.component('app-comment-reply', CommentReply);
Vue.component('app-comment-edit', CommentEdit);
Vue.component('app-comment-list', CommentList);
Vue.component('app-comment-detail', CommentDetail);
Vue.component('app-comment-dialog', CommentDialog);
Vue.component('app-comment-list-items', CommentListItems);
Vue.component('app-log-data-dialog', LogDataDialog);
Vue.component('app-datepicker', Datepicker);
Vue.component('app-dialog', AppDialog);
Vue.component('app-page-edit', AppPageEdit); 
Vue.component('app-page-detail', AppPageDetail);
Vue.component('app-right-bar', AppRightBar);
Vue.component('app-button-close', AppButtonClose);
Vue.component('app-button', AppButton);
Vue.component('app-date-time', AppDateTime);
Vue.component('app-document-status', AppDocumentStatus);
Vue.component('app-user-info', AppUserInfo);
Vue.component('app-text-info', AppTextInfo);

Vue.use(VueMeta, {
  keyName: 'head',
})

new Vue({
  router,
  store,
  vuetify,
  VueMeta,
  render: h => h(App)
}).$mount('#app')
