<template>
  <v-dialog width="500" v-model="internalValue">
    <v-card>
      <v-card-title>Write Comment</v-card-title>

      <v-card-text>
        <v-textarea v-model="comment_body" outlined class="fill-height" hide-details></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="saveComment()">Submit</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="internalValue = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    document_no_: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: null,
      internalValue: this.value,
      comment_body: "",
    }
  },

  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        this.$emit('callback');
      }
    },
  },

  methods: {
    close() {
      this.internalValue = false;
    },

    async saveComment() {
      if (this.comment_body.toString().trim().length === 0) return alert('Can\'t empty!');
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("document_id", this.document_id);
      formData.append("source_document", this.source_document);
      formData.append("document_no_", this.document_no_);
      formData.append("comment_body", this.comment_body);
      await this.$axios.post("comments/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.comment_body = ""

          this.close();
        })
        .catch((error) => {
          this.loading = false
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.close();

        });
    },
  },

  mounted() {
    this.comment_body = ""
  }
}
</script>