var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',_vm._g(_vm._b({attrs:{"elevation":"0","outlined":""},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},'v-card',_vm.$attrs,false),_vm.$listeners),[_c('v-toolbar',{attrs:{"elevation":"0","dense":""}},[_c('v-toolbar-title',[_c('v-icon',[_vm._v("mdi-comment-multiple")]),_vm._v(" Comments")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){_vm.dialog_comment = true}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-window-maximize")])]}}])},[_c('span',[_vm._v("Pop Out")])])],1),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){return _vm.getData(true)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":function($event){_vm.dialog_add = true}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-comment-plus-outline")])]}}])},[_c('span',[_vm._v("Add New Comment")])])],1)],1),_c('v-divider'),_c('app-comment-list-items',{ref:"refAppCommentListItems",attrs:{"document_id":parseInt(_vm.document_id),"source_document":_vm.source_document,"document_no_":_vm.document_no_}})],1),_c('app-comment-add',{attrs:{"document_id":parseInt(_vm.document_id),"source_document":_vm.source_document,"document_no_":_vm.document_no_},on:{"callback":function($event){return _vm.getData(true)}},model:{value:(_vm.dialog_add),callback:function ($$v) {_vm.dialog_add=$$v},expression:"dialog_add"}}),_c('app-comment-dialog',{attrs:{"document_id":parseInt(_vm.document_id),"source_document":_vm.source_document,"document_no_":_vm.document_no_,"title":_vm.dialog_comment_title},on:{"callback":function($event){return _vm.getData(true)}},model:{value:(_vm.dialog_comment),callback:function ($$v) {_vm.dialog_comment=$$v},expression:"dialog_comment"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }