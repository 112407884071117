<template>
  <v-menu offset-y max-height="600" max-width="340" bottom left :close-on-content-click="false" v-model="menu" close-delay="1">
    <template v-slot:activator="{ attrs, on }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-badge color="primary" dot overlap v-if="showNewNotif"><v-icon>mdi-bell</v-icon></v-badge>
        <v-icon v-if="!showNewNotif">mdi-bell</v-icon>
      </v-btn>
    </template>

    <v-sheet>
      <v-card width="300px" class="px-0" :loading="loading" :disabled="loading">
        <v-toolbar dense elevation="0" class="px-0">
          <v-toolbar-title>Notification</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getList" small>
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <div style="max-height: 300px;overflow-y: auto" v-if="!loading" class="px-1 my-1">
          <v-list-item v-for="(notif, i) in notificationList" :key="i" dense two-line @click="openItem(notif)" :class="(notif.read_status) ? 'grey lighten-3' : ''" :style="(notif.read_status) ? 'border-bottom:#ddd 1px solid !important;' : ''" :disabled="loading_item">
            <v-list-item-content class="py-0 my-0">
              <v-list-item-title>{{ notif.title }}</v-list-item-title>
              <v-list-item-subtitle>
                <div v-html="notif.content"></div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-card-text v-if="notificationList.length == 0 && loading == false">
          <span>Not found!</span>
        </v-card-text>
        <v-skeleton-loader v-if="loading == true" class="mx-auto" max-width="300" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader v-if="loading == true" class="mx-auto" max-width="300" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader v-if="loading == true" class="mx-auto" max-width="300" type="list-item-two-line"></v-skeleton-loader>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showPrev();" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
          <v-btn @click="showNext();" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loading_item: false,
      benched: 0,
      notificationList: [],
      isShowBadge: false,
      currentPage: 0,
      totalPage: 1,
      menu: false,
    }
  },

  computed: {
    showNewNotif() {
      let unread = 0;
      for (var i = 0; i < this.notificationList.length; i++) {
        if (!this.notificationList[i].read_status) {
          unread = unread + 1;
        }
      }

      return (unread > 0) ? true : false;
    },
    unread() {
      let unread = 0;
      for (var i = 0; i < this.notificationList.length; i++) {
        if (!this.notificationList[i].read_status) {
          unread = unread + 1;
        }
      }

      return unread;
    }
  },

  watch: {
  },

  methods: {
    async getListAuto() {
      setTimeout(() => {
        this.getList();
        this.getListAuto();
      }, this.$config.notificationRefreshTime)
    },
    async getList() {
      this.loading = true;
      await this.$axios.get('notification/list', {
        cacheConfig: false,
        params: {
          p: this.currentPage
        }
      }).then((res) => {
        if (!this.isUndefined(res.data)) {
          this.notificationList = res.data.data.list;
          this.currentPage = res.data.data.current_page;
          this.loading = false;

          this.isShowBadge = (res.data.data.unread >= 1) ? true : false;
        }
      });
    },
    async openItem(item) {
      this.loading_item = true;
      const params = {
        id: item.id
      }
      await this.$axios.get('notification/open', {
        params: params
      }).then((res) => {
        if (!this.isUndefined(res.data)) {
          console.log(res.data);
          this.redirectTo(res.data.data.self_url, res.data.data.redirect_url)
        }
        this.loading_item = false;
      })
        .catch((error) => {
          this.loading_item = false;
          this.axiosErrorHandler(error);
        });
    },
    showPrev() {
      this.currentPage = this.currentPage - 1;

      if (this.currentPage <= 0) {
        this.currentPage = 1;
      }
      this.getList();
    },
    showNext() {
      this.currentPage = this.currentPage + 1;

      if (this.currentPage >= this.totalPage) {
        this.currentPage = this.totalPage;
      }
      this.getList();
    },

    redirectTo(self_url, url) {
      let istMe = self_url;
      this.menu = false;

      // console.log(url);
      

      if (url.toString().search(this.$config.base_url_domain)) {

        istMe = true;
      }

      if (istMe) {
        url = url.toString().replace(this.$config.base_url, "");
        url = url.toString().replace(this.$config.base_url_domain, "");
        console.log(url);
        
        this.$router.push(url);
      } else {
        this.goToExternalURL(url);
      }
    }
  },

  mounted() {
    this.totalPage = 1;
    this.getList();
  },
}
</script>