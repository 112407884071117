const options = {
  'mjo': {
    'status': ['Open', 'Normal', 'Critical', 'High'],
    'substatus': ['received', 'on-progress', 'waiting-sparepart', 'pending', 'done'],
    'priority': ['Low', 'Normal', 'Critical', 'High'],
    'job_type': ['Perbaikan Terencana', 'Perawatan Terjadwal', 'Perbaikan Tidak Terencana', 'Project / Improvement', 'Perbaikan Alat Bantu'],
    'job_level': ['Sangat Mudah', 'Mudah', 'Sedang', 'Sulit', 'Sangat Sulit'],
  }
}

export default options;